@import "../../../../../../assets/stylesheets/var";

.opened-with-image-model {
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  .preview-close{
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
  }
  .preview-text {
    font-family: $quicksand;
    font-weight: 700;
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: 4.2%;
  }
  .close-img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .image-section-main-div {
    display: flex;
    min-height: 70vh;
    width: 100%;
    background: #f5f5f5;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 8px;

    .img-dekstop-style {
      width: auto;
      height: auto;
      object-fit: contain;
      // margin: 0 auto;
      
      &.full-width { max-width: 100%; }
      &.half-width { max-width: 1000px; }
      &.full-height { max-height: 70vh; }
      &.half-height { max-height: 550px; }
    }

    &.position-topLeft {
        justify-content: flex-start;
        align-items: flex-start;
      }
      
      &.position-topRight {
        justify-content: flex-end;
        align-items: flex-start;
      }
      
      &.position-topCenter {
        justify-content: center;
        align-items: flex-start;
      }
      
      &.position-center {
        justify-content: center;
        align-items: center;
      }
      
      &.position-bottomLeft {
        justify-content: flex-start;
        align-items: flex-end;
      }
      
      &.position-bottomRight {
        justify-content: flex-end;
        align-items: flex-end;
      }
      
      &.position-bottomCenter {
        justify-content: center;
        align-items: flex-end;
      }
    
      &.position-centerLeft {
        justify-content: flex-start;
        align-items: center;
      }
    
      &.position-centerRight {
        justify-content: flex-end;
        align-items: center;
      }
  }
  .image-section-mobile-main-div {
    display: flex;
    min-height: 70vh;
    width: 30%;
    background: #f5f5f5;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 8px;
    margin: 0 auto;
    border: 1px solid #344e41;
    &.position-center {
      justify-content: center;
      align-items: center;
    }
    &.position-bottom {
      justify-content: center;
      align-items: flex-end;
    }
    .img-mobile-style {
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
      margin: 0 auto;
    }
  }
  .name-dimensions {
    padding: 20px;
    .image-name {
      font-family: $quicksand;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $primary-text;
      padding-bottom: 15px;
    }
    .download-icon-div{
        cursor: pointer;
        .download-icon {
            width: 20px;
            height: 20px;
            margin-right: 7px;
        }
    }
    .dimensions {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 14px;
      line-height: 17.5px;
      letter-spacing: 1.5%;
      color: $secondary-text;
    }
    .margin-top15 {
      display: flex;
      margin-top: 15px;
      cursor: pointer;
    }
    .download-text {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 14px;
      line-height: 17.5px;
      letter-spacing: 1.5%;
      color: $secondary-text;
      padding-right: 12px;
    }
    .download-image-icon {
      width: 17px;
      height: 17px;
    }
  }
}

