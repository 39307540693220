@import "../../../../../assets/stylesheets/var";

.feedback-container{
.image-text{
    font-weight: 700;
    font-size: 16px;
    color: $secondary-text;
    
  }
  .image-text-heading {
    font-weight: 600;
    font-size: 16px;
    color: $light-gray;
    margin-left: 10px;
  }

.common-input {
    border-radius: 10px;
    border: 1.5px solid $tert-text;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    background: $background;
  }

  .add-tag-input-label {
    color: $primary-text;
    font-family: $quicksand;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }
  .attachment-icon{
    height: 50px;
    width: 50px;
  }
  .img-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-1;
    border-radius: 10px;
    border: 1px dashed $dark-gray;
    height: 120px;
    width: 100%;
    margin: 1rem 0;
    position: relative;
}
.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    width: 100%;
    height: 150px;
  }
  
.inputContainer input {
    position: absolute;
    inset: 0;
    width: 100%;
    opacity: 0;
}

.close-img-style {
    height: 20px;
    width: 20px;
  }

  .file-text{
    font-weight: 700;
    font-size: 16px;
    color: $primary-text;
  }
  .drag-file-text{
    font-weight: 600;
    font-size: 16px;
    color: $dark-gray;
    font-family: $quicksand;
    font-size: 0.875rem;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }
  .image-text-cont{
    padding: 1rem;
    gap: 50px
  }
}