@import "../../../../../../assets/stylesheets/var";

.add-campaign-edit-image{
  .close-img-div {
    position: absolute;
    right: 20px;
    z-index: 1000;
    top: 10px;
    cursor: pointer;
    
    .close-img {
      width: 20px;
      height: 20px;
    }
  }
  
  .editor-container {
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    margin: 35px;

    .editor-content {
      flex: 1;
      display: flex;
      gap: 20px;
      position: relative;
      height: calc(100% - 20px);

      .cropper-container {
        flex: 2;
        position: relative;
        border: 1px solid #eee;
        border-radius: 8px;
        overflow: hidden;

        .tools-panel {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          z-index: 1000;
          display: flex;
          flex-direction: column;
          gap: 16px;
          border-radius: 12px;
          padding: 12px;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

          .tool-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            transition: background-color 0.2s;
            background-color: white;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }

        .cropper {
          height: calc(100% - 20px);
          width: 100%;
          max-height: none;
        }
      }

      .preview-container {
        flex: 1;
        overflow: auto;
        padding: 20px;
        background-color: #f5f5f5;
        border-radius: 8px;
        max-height: 100%;

        h3 {
          margin-bottom: 15px;
          font-size: 18px;
        }

        .preview-image-container {
          position: relative;

          img {
            width: 100%;
            height: auto;
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .dimensions-badge {
            position: absolute;
            bottom: 8px;
            right: 8px;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
          }
        }
      }
    }

    .loader-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: 20px;
    }
  }
  
  // .editor-content {
  //   flex: 1;
  //   display: flex;
  //   gap: 20px;
  //   position: relative;
  //   height: calc(100% - 80px);
  // }
  
  // .cropper-container {
  //   flex: 2;
  //   position: relative;
  //   border: 1px solid #eee;
  //   border-radius: 8px;
  //   overflow: hidden;
  //   min-height: 400px;
  // }
  
  // .cropper {
  //   height: 100% !important;
  //   width: 100% !important;
  //   max-height: none;
  //   object-fit: contain;
  // }
  
  // .image-container {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
    
  //   &.error {
  //     color: #ff4d4f;
  //   }
  // }
  
  // .preview-container {
  //   flex: 1;
  //   overflow: auto;
  //   padding: 20px;
  //   background-color: #f5f5f5;
  //   border-radius: 8px;
  //   max-height: 100%;
  //   display: flex;
  //   flex-direction: column;
  // }
  
  // .preview-image {
  //   max-width: 100%;
  //   height: auto;
  //   border-radius: 4px;
  //   margin-bottom: 20px;
  // }
  
  // .controls-container {
  //   display: flex;
  //   gap: 10px;
  //   margin-top: 20px;
  //   flex-wrap: wrap;
  // }
}

