@import "../../../../../../assets/stylesheets/var";

.image-design-preview-component {
  .position-dropdowns-container {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 95.5%;
    margin-top: 1%;
    
    > div {
      flex: 1;
    }
  }
  // .generated-designs-campaign-name {
  //   font-family: $quicksand;
  //   font-weight: 700;
  //   font-size: 16px;
  //   line-height: 20.7px;
  //   letter-spacing: 7%;
  //   color: $secondary-text;
  //   padding-top: 5%;
  // }

  .preview-image-container {
    width: 330px;
    height: 283px;
  }
  

  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .preview-loading-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .preview-edit-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }  

  .add-compaign-edit-image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  }

  .flex-space{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .button-container-right-padding{
    padding-right: 12px;
  }
}
