@import "../../../../../../assets/stylesheets/var";


.saved-image-campaign{
    .expand-collapse-icons{
      width: 24px;
      height: 24px;
    }
    .main-page-margins{
    padding-left: 17px;
    padding-right: 17px
    }
    .top-margin{
        margin-top: "20px"
    }
    .text-left{
        padding-left: 20px;
    }
    .bottom-margin{
        padding-bottom: 20px;
    }
    
    .bottom-tile-margin{
        padding-bottom: 2%
    }

    .padding-top-2 {
        padding-top: 2%;
      }
      
      .padding-top-20 {
        padding-top: 20px;
      }
      
      .text-underlined {
        text-decoration: underline;
        cursor: pointer;
      }
      
    .scroll-container{
        max-height: 700px;
        overflow-y: auto
    }
    .pointer{
        cursor: pointer;
    }
    .edit-icon{
        width: 24px ;
        height: 24px;
        margin-top: 17px;
    }
    .scroll-container-buttons{
        position: relative;
        width: 100%
    }
    .left-button{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        color: white;
        z-index: 1;
        cursor: pointer;
        opacity: 2; 
        transition: opacity 0.3s; 
        .back-image{
          opacity: 1;
        }
      }
      .right-button{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        color: white;
        z-index: 1;
        cursor: pointer;
        opacity: 2;
        transition: opacity 0.3s; 
        .back-image{
          opacity: 1;
        }
      }
      .new-scroll-container{
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        padding-bottom: 15px
      }
}