@import "../../../../../../assets/stylesheets/var";
.web-image-advertisement{
    .image-style {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }    
    .padding-bottom-10 {
        padding-bottom: 10%;
      }  
}