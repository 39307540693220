@import "../../../.././../assets/stylesheets/var";

.persona-management {
  .button-group {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 5px;
  }
  .edit-button {
    cursor: pointer;
    border: 0;
    outline: 0;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .delete-button {
    cursor: pointer;
    border: 0;
    outline: 0;
    background: none;
    margin-left: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .header-text{
    font-weight: 700;
    font-size: 24px;
    font-family: $quicksand;
  }
  .labels-text{
    font-weight: 700;
    font-size: 16px;
    color: $secondary-text;
  }
  .MuiPaper-root.MuiDialog-paper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
    border-radius: 20px;
  }
  .gap-20{
    gap: 20px;
    padding-bottom: 30px;
  }

  .common-input {
    border-radius: 10px;
    border: 1.5px solid $tert-text;
    width: 100%;
    padding: 13px;
    margin-top: 5px;
    background: $background;
  }

  .add-tag-input-label {
    color: $primary-text;
    font-family: $quicksand;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }

  .close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-right: 20px;
  }

  h4 {
    color: $primary-color;
    padding: 10px 0;
  }
  .delete-persona-group {
    width: 274px;
    height: 270px;
    margin: 50px 0 20px 0;
  }
  .delete-text {
    font-weight: 500;
    font-size: 16px;
    font-family: $quicksand;
  }
  .delete-persona-text {
    font-weight: 500;
    font-size: 20px;
    font-family: $quicksand;
    margin-top: 20px;
  }
  .error-labels-text{
    font-weight: 500;
    font-size: 1rem;
    color: $status-negative;
    letter-spacing:"0.8px";
    margin-top: 5px;
  }

}
