@import "../../../../../../assets/stylesheets/var";


.add-campaign {
  .dotted-progress-container {
    --uib-size: 35px;
    --uib-color: #9EAC5F;
    --uib-speed: 1.3s;
    --uib-dot-size: calc(var(--uib-size) * 0.24);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--uib-size);
  }

  .connection-text{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }
 
  .dotted-progress,
  .dotted-progress-container::before,
  .dotted-progress-container::after {
    content: '';
    display: block;
    height: var(--uib-dot-size);
    width: var(--uib-dot-size);
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    transition: background-color 0.3s ease;
  }
 
  .dotted-progress-container::before {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
      infinite;
  }
 
  .dotted-progress {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
      infinite both;
  }
 
  .dotted-progress-container::after {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
      infinite;
  }
 
  @keyframes pulse {
    0%,
    100% {
      transform: scale(0);
    }
 
    50% {
      transform: scale(1);
    }
  }

  input[type="checkbox"] {
    appearance: none;
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid $fourth-text;
    border-radius: 4px;
  }
  input[type="checkbox"]:before {
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    margin: 2px;
    border-radius: 2px;
  }
  input[type="checkbox"]:checked::before {
    background-color: $graph-axis-color;
  }
  .add-set-campaign {
    margin-left: 1%;
    margin-top: 2%;
  }
  .set-campaign-text {
    font-family: $quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: $secondary-text;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .generate-button {
    margin-bottom: 10px;
    background-color: rgba(208, 208, 208, 1);
    height: 47px;
    border-radius: 7px;
    cursor: pointer;
    margin-left: 1%;
    margin-right: 1%;
    &.selected {
      background-color: $primary-color;
    }
  }
  .generate-button-text {
    font-family: $quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: rgba(118, 118, 118, 1);
    &.selected {
      color: $background;
    }
  }
  .use-images {
    padding-bottom: 1%;
    cursor: pointer;
    .use-images-text {
      font-family: $quicksand;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: rgba(181, 181, 181, 1);
      margin-left: 1%;
      margin-top: 1%;
    }
    .use-images-text-selected {
      font-family: $quicksand;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: rgb(52, 78, 65);
      margin-left: 1%;
      margin-top: 1%;
    }
  }
}