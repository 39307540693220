*,
*::before,
*::after {
  box-sizing: border-box;
}
img {
  width: 100%;
  font-size: 0;
}
a {
  text-decoration: none;
  color: #000000;
  display: inline-block;
  font-family: $quicksand;
  font-size: 1rem;
  letter-spacing: 0%;
}
.btn-text {
  font-family: $quicksand;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $quicksand;
}
body {
  font-family: $quicksand;
}

/* Apply public-sans font-family to all tags containing numbers */
.number {
  font-family: $quicksand;
}

// container we will use everywhere inside dashboard

.container {
  display: grid;
  position: relative;
  margin: 0;
  min-width: 1500px;
  grid-template-columns: 70%;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
  justify-content: center;
  gap: 10px;
  padding-top: 2rem;
  padding-bottom: 7.5rem;
}

// this is being used in auth flow

.container-2 {
  max-width: 1150px;
  margin: 0 auto;
  padding: 2rem 0 7.5rem 0;
}

// text-center
.text-center {
  text-align: center;
}

// hidden
.hidden {
  display: none;
}

.visible {
  display: block;
}

button {
  font-family: $quicksand;
}

.react-tooltip {
  font-family: $quicksand;
  font-weight: 500;
  background-color: $primary-text !important;
  opacity: 1 !important;
  border-radius: 10px !important;
}

.asterisk {
  color: #ff6464;
}

.loaders {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.background-box {
  background-color: $background;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.web-link{
  color: #474747;
  font-size: 0.75rem;
  font-weight: 600;
}

input,
select,
textarea:focus-visible {
  outline-color: $primary-color-2-shade-2;
}

// Delete icon class
.delete-multiple {
  background: url("../icons/buttons.svg") no-repeat;
  border: none;
  width: 50px;
  cursor: pointer;
}
// Delete icon class ends

// Modules, settings headings
.modules-heading{
  font-family: $quicksand;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: 1.008px;
  color: $primary-text;
}
// Modules, settings headings ends

.input-field-common{
  position: relative;
  margin-top: 1%;
  margin-right: 20%;
  padding-left: 12px;
  width: 95%;
  height: 47px;
  border-radius: 9px;
  font-family: var(--fontQuickSand); /* Assuming themeColors.fontQuickSand is a CSS variable */
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  border: 1.5px solid #ccc;
}

.input-field-common-error{
  position: relative;
  margin-top: 1%;
  margin-right: 20%;
  padding: 7px;
  padding-left: 12px;
  width: 95%;
  height: 47px;
  border-radius: 9px;
  font-family: var(--fontQuickSand); /* Assuming themeColors.fontQuickSand is a CSS variable */
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  border: 1.5px solid red;
}

.input-error-text{
  color: red;
}

.heading-bottom{
  margin-bottom: 1.88rem;
}

.backgroundSize{
  background-size: 50px;
}

.backgroundSizeMobile{
  background-size: 40px;
}

.padding-basic{
  padding: 1rem;
}


// typography
.body-text-regular {
  font-size: 1rem;
  line-height: auto;
}


.body-text-medium {
  font-size: 1rem;
  line-height: auto;
  font-weight: 500;
}

.body-text-bold {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 600;
}

.body-text-extra-bold {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 700;
}

.body-text-main {
  font-size: 1.25rem;
  line-height: 1.3;
}

.body-text-main-bold {
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 500;
}

.body-text-main-small {
  font-size: 0.75rem;
  line-height: 1.3;
}

// bar graph component height

.general-bar-graph-height {
  height: 22.5rem;
}

.comparison-bar-graph-height {
  height: 30rem;
}

.minimum-height-for-pages {
  min-height: 22.5rem;
}

.margin-top-10 {
 margin-top: 10px;
}

.position-relative{
  position: relative;
}

.gap-10{
  gap: 10px
}

.illustration-heading {
  color: $background;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: 1.008px;
}

.illustration-background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $illustration-background;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px
}

.illustration-icon{
  display: flex;
  justify-content: "center";
  height: 300px;
  margin: 30px 0px;
  }
.full-height {
  height: 100%;
}

.margin-top-1rem {
  margin-top: 1rem;
 }

 .margin10{
  margin: 10px
 }

.margin-left-20{
  margin-left: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.persona-heatmap-header {
  display: flex;
  align-items: center;
  color: $primary-text;
  font-size: 0.9rem
}

.toggle-text{
  font-family: $quicksand;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 5%;
  padding:0 5px;
  color: $secondary-text;
}
